import i18n from "i18next";

import enStrings from "./languages/en";
import ruStrings from "./languages/ru";

i18n.init({
  resources: {
    en: enStrings,
    ru: ruStrings,
  },
  lng: window.clientConfig.locale,
  fallbackLng: "en",
  debug: window.appConfig.isDebug,
  ns: ["strings"],
  defaultNS: "strings",
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },
  react: {
    wait: true
  }
}, () => {
  window.axios.defaults.headers.common["X-Locale"] = i18n.language;
});

export default i18n;
