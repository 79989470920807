import promiseRetry from "promise-retry";

export const apiResponseErrorCodes = {
  INTERNAL: 1,
  INVALID_PARAMS: 2,
  PHOTOLAB: 3,
  NOT_AUTHORIZE: 401,
  NOT_FOUND: 404,
  DELETED: 410,
  FILE_FORMAT_INVALID: 415,
};

const defaultRetriesConfig = {
  retries: 3,
  minTimeout: 1000,
  maxTimeout: 3000
};

export class ApiResponseError extends Error {

  constructor(data) {
    super();

    this.name = "ApiResponseError";
    this.code = data.error_code;
    this.message = data.error_message;
    this.response = data;
  }
}

function checkApiResponse(res) {
  if (res.data.error_code) {
    throw new ApiResponseError(res.data);
  } else {
    return res.data;
  }
}

export function photolabSign(data) {
  const params = {data};
  params.rv = 1;
  params.cv = 1;
  params.cn = window.appConfig.clientName;

  return window.axios.post(window.appConfig.paths.apiSign + "/photolab/sign", params)
    .then(checkApiResponse);
}

export function createFile(file, data, params) {
  params = params || {};

  const formData = new FormData();

  if (file instanceof File) {
    formData.append("file", file);
  } else {
    formData.append("image_url", file);
  }

  if (data) {
    formData.append("image_data", JSON.stringify(data || {}));
  }

  Object.keys(params).forEach((pk) => formData.append(pk, params[pk]));

  return window.axios.post(window.appConfig.paths.apiUpload + "/files/create", formData, {
    headers: {"Content-Type": "multipart/form-data"}
  }).then(checkApiResponse);
}

export function createPDF(file, params) {
  params = params || {};

  const formData = new FormData();
  formData.append("file", file);

  Object.keys(params).forEach((pk) => formData.append(pk, params[pk]));

  return window.axios.post(window.appConfig.paths.apiUpload + "/files/create/pdf", formData, {
    headers: {"Content-Type": "multipart/form-data"}
  }).then(checkApiResponse);
}

export function imageEncodeAsDataUrl(url) {
  function requestFunc(retry) {
    return window.axios.get(window.appConfig.paths.api + "/image/encode/data-url?content_url=" + encodeURIComponent(url))
        .catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
      .then(checkApiResponse);
}

export function enqueueCreativeStoreTask(creative, taskName, contentUrl, watermark, isPersistent = true) {
  return createTask("creative_store", {content_url: contentUrl, watermark, is_persistent: isPersistent});
}

export function enqueueCollageTask(creative, taskName, contentUrls, watermark, isPersistent = true) {
  return createTask("collage", {content_urls: contentUrls, watermark, is_persistent: isPersistent});
}

export function enqueueQRCodeCreateTask(contentUrl, codeContent, qrcodeStyles, qrcodeVersion = "auto", isPersistent = true) {
  return createTask("qrcode_create", {
    content_url: contentUrl,
    code_content: codeContent,
    qrcode_styles: qrcodeStyles,
    qrcode_version: qrcodeVersion,
    is_persistent: isPersistent,
  });
}

export function createTask(type, params) {
  function requestFunc(retry) {
    return window.axios.post(window.appConfig.paths.api + "/tasks/create", {type, params}).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .then(checkApiResponse);
}

export function fetchTask(taskId) {
  function requestFunc(retry) {
    return window.axios.get(window.appConfig.paths.api + "/tasks/" + taskId).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .then(checkApiResponse);
}

export function telegramPutQRCodeData(data) {
  function requestFunc(retry) {
    return window.axios.post(window.appConfig.paths.api + "/telegram/qrcode-data", {data}).catch(retry);
  }

  return promiseRetry(requestFunc, defaultRetriesConfig)
    .then(checkApiResponse);
}