import React from "react";
import PropTypes from "prop-types";
import i18n from "../../i18n";

export default function CreativeErrorView(props) {
  const isPhotolabError = props.creative.error.type === "photolab";

  return <div className="error-container">
    {!isPhotolabError && <div className="creative-error-message-container">
      <div className="creative-error-message">
        <p>{i18n.t("creative_failed_message")}</p>
      </div>
    </div>}

    {isPhotolabError && <div className="creative-error-message-container">
      <div className="creative-error-message">
        <p>{i18n.t("creative_failed_message")}</p>
      </div>
    </div>}
  </div>;
}

CreativeErrorView.propTypes = {
  creative: PropTypes.object.isRequired,
}