import * as api from "../../utils/api";
import PhotolabTaskBuilder from "../PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../PhotolabTaskImageUrl";
import {
  defaultHandlerCatch,
  defaultHandlerResolver,
  HandlerCancelError,
  photolabTask,
  waitCreative,
  waitTaskHelper
} from "./helpers";
import Creative from "../Creative";
import {creativeGroups} from "../groups";

// кроп для проверки кол-ва лиц
function cropTask(processing, creative) {
  const cropCreative = processing.creatives.find((c) => c.inGroup(creativeGroups.COMMON) && c.templateId === 3410);

  return waitCreative(cropCreative)
    .then((creative) => {
      if (creative.isFailed) {
        throw new HandlerCancelError("The crop creative is failed", creative.error)
      }

      return creative;
    });
}

function headTask(processing, creative) {
  return photolabTask(creative, "preparation", new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod(6032))
    .addImage(new PhotolabTaskImageUrl(processing.file.url, processing.file.rect, processing.file.rotation, processing.file.flip))
    .build());
}

function templateTask(processing, creative, imageUrl) {
  return photolabTask(creative, "template", new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
    .addImage(new PhotolabTaskImageUrl(imageUrl))
    .build());
}

function preloadImage(creative, resolve) {
  return () => {
    if (creative.isProcessed) {
      const resultImageUrl = creative.result[0].url;
      if (resultImageUrl) {
        new Image().src = resultImageUrl;
      }
    }

    resolve(creative);
  };
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export default (processing, creative) => {
  return new Promise((resolve, reject) => {
    cropTask(processing, creative)
      .then((taskResult) => headTask(processing, creative))
      .then((taskResult) => templateTask(processing, creative, taskResult.resultUrl))
      .then((taskResult) => api.enqueueQRCodeCreateTask(taskResult.resultUrl, processing.codeContent, creative.getExtra(Creative.EXTRA_QRCODE_STYLES)))
      .then((taskResult) => waitTaskHelper(creative, "qrcode_create", taskResult, 1000))
      .then((taskResult) => {
        creative.markAsProcessed(taskResult.result)
      })
      .then(preloadImage(creative, resolve))
      .then(defaultHandlerResolver(creative, resolve))
      .catch(defaultHandlerCatch(creative, reject));
  });
}
