import React from "react";
import i18n from "../../i18n";
import PropTypes from "prop-types";
import {saveAs} from "file-saver";
import CreativeView from "./CreativeView";
import * as webviewUtils from "../../utils/webview";
import FileChooseButton from "../../components/FileChooseButton";
import {createMd5Token} from "../../utils/text";
import {logEvent, userEvents} from "../../utils/log";
import Processing from "../../photolab/Processing";

export default class TabContentView extends React.Component {

  handleDownloadClick = (creative) => {
    logEvent(userEvents.DOWNLOAD, {
      group: creative.group,
      template_id: creative.templateId,
      is_refresh: creative.isRefreshed,
      code_source: window.processingManager.processing.getExtra(Processing.SOURCE, null),
      code_content: window.processingManager.processing.codeContent,
    });

    const fileUrl = creative.result[0].url;

    const fileName = "qrtoon.com_"
      + createMd5Token(Date.now()).substr(0, 6)
      + "." + fileUrl.substring(fileUrl.lastIndexOf(".")+1);

    setTimeout(() => {
      if (window.clientConfig.isWebview) {
        webviewUtils.webviewShareDownload(fileUrl);
      } else {
        const url = new URL(window.appConfig.paths.api);
        url.pathname = "/download";
        url.searchParams.append("content_url", fileUrl);

        saveAs(url.toString(), fileName);
      }
    }, 100);
  };

  renderRefreshButton = () => {
    const className = window.clientConfig.splitGroupId > 5
      ? "btn white"
      : "btn-choose";
    const text = [1,2,3,6,7,8].indexOf(window.clientConfig.splitGroupId) > -1
      ? i18n.t("result__more_styles")
      : i18n.t("result__refresh");

    return <button
      onClick={this.props.onRefreshClick}
      className={className}
      children={text} />;
  }

  render() {
    const groupCreatives = window.processingManager.processing.getCreativesInGroup(this.props.group);
    const creative = groupCreatives.find((c) => c.isSelected);

    return <React.Fragment>
      {creative && creative.isProcessed && <CreativeView
        key={creative.result[0].url}
        creative={creative} />}

      <div className="btns-container">
        <FileChooseButton
          onFileSelected={this.props.onFileSelected}
          className="btn-choose"
          children={i18n.t("result__choose_photo")} />

        <button
          onClick={this.props.onRestartClick}
          className="btn-choose"
          children={i18n.t("result__create_new")} />

        {this.renderRefreshButton()}

        {creative && creative.isProcessed && <button
          className="btn btn-next"
          dangerouslySetInnerHTML={{__html: i18n.t("result__download")}}
          onClick={() => this.handleDownloadClick(creative)} />}
      </div>
    </React.Fragment>;
  }
}

TabContentView.propTypes = {
  group: PropTypes.string.isRequired,
}