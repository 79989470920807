import * as api from "../../utils/api";
import PhotolabTaskBuilder from "../PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../PhotolabTaskImageUrl";
import {defaultHandlerCatch, defaultHandlerResolver, photolabTask, waitTaskHelper} from "./helpers";
import Creative from "../Creative";

function headTask(processing, creative) {
  return photolabTask(creative, "head", new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod(5148))
    .addImage(new PhotolabTaskImageUrl(processing.file.url, "", 0, 0))
    .build());
}

function templateTask(processing, creative, imageUrl) {
  return photolabTask(creative, "template", new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
    .addImage(new PhotolabTaskImageUrl(imageUrl, "", 0, 0))
    .build());
}

function backgroundTask(processing, creative, imageUrl) {
  return photolabTask(creative, "background", new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod(1978))
    .addImage(new PhotolabTaskImageUrl(imageUrl, "", 0, 0))
    .addImage(new PhotolabTaskImageUrl(creative.getExtra(Creative.EXTRA_BACKGROUND), "", 0, 0))
    .build());
}

function preloadImage(creative, resolve) {
  return () => {
    if (creative.isProcessed) {
      const resultImageUrl = creative.result[0].url;
      if (resultImageUrl) {
        new Image().src = resultImageUrl;
      }
    }

    resolve(creative);
  };
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export default (processing, creative) => {
  return new Promise((resolve, reject) => {
    headTask(processing, creative)
      .then((taskResult) => templateTask(processing, creative, taskResult.resultUrl))
      .then((taskResult) => backgroundTask(processing, creative, taskResult.resultUrl))
      .then((taskResult) => api.enqueueQRCodeCreateTask(taskResult.resultUrl, processing.codeContent, creative.getExtra(Creative.EXTRA_QRCODE_STYLES)))
      .then((taskResult) => waitTaskHelper(creative, "qrcode_create", taskResult, 1000))
      .then((taskResult) => {
        creative.markAsProcessed(taskResult.result)
      })
      .then(preloadImage(creative, resolve))
      .then(defaultHandlerResolver(creative, resolve))
      .catch(defaultHandlerCatch(creative, reject));
  });
}
