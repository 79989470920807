import Creative from "./Creative";
import {creativeGroups} from "./groups";

export default class Processing {

  static SOURCE = "source";
  static EXTRA_CREATED_AT = "created_at";
  static EXTRA_STARTED_AT = "started_at";

  _file = null;
  _codeContent = "";
  _groups = [];
  _creatives = [];
  _language = "en";
  _extra = {};
  _tasks = {};

  get file() {
    return this._file;
  }

  get codeContent() {
    return this._codeContent;
  }

  get language() {
    return this._language;
  }

  /** @returns {Creative[]} */
  get creatives() {
    return this._creatives;
  }

  get groups() {
    return this._groups;
  }

  setFile(file) {
    this._file = file;
  }

  setCodeContent(content) {
    this._codeContent = content;
  }

  setGroups(groups) {
    this._groups = groups;
  }

  setLanguage(language) {
    this._language = language || "en";
  }

  setTask(key, task) {
    this._tasks[key] = task;
  }

  getTask(key) {
    return this._tasks[key] || null;
  }

  addCreative(creative) {
    creative.setFileId(this.file.id);

    this.creatives.push(creative);
  }

  removeCreative(creative) {
    const index = this.creatives.findIndex(c => c.id === creative.id);

    if (index !== -1) {
      this.creatives.splice(index, 1);
    }
  }

  getCreativesInGroup(group) {
    return this.creatives.filter((c) => c.inGroup(group));
  }

  getSelectedCreativeInGroup(group) {
    return this.creatives.find((c) => c.inGroup(group) && c.isSelected);
  }

  getGender() {
    const genderCreative = this.getCreativesInGroup(creativeGroups.COMMON)
      .find((c) => c.templateId === "gender");

    if (genderCreative && genderCreative.isProcessed) {
      return genderCreative.result;
    }

    return "unspecified";
  }

  setExtra(key, value) {
    this._extra[key] = value;
  }

  hasExtra(key) {
    return this._extra.hasOwnProperty(key);
  }

  getExtra(key, defaultValue) {
    return this._extra[key] || defaultValue;
  }

  toJSON(pretty = false) {
    const output = {
      file: this._file,
      codeContent: this._codeContent,
      groups: this._groups,
      creatives: this._creatives.map((creative) => creative.data),
      language: this._language,
      extra: this._extra,
      tasks: this._tasks,
    };

    return pretty
      ? JSON.stringify(output, null, 2)
      : JSON.stringify(output);
  }

  fromObject(obj) {
    this._file = obj.file || null;
    this._codeContent = obj.codeContent || null;
    this._groups = obj.groups || [];
    this._language = obj.language || "en";
    this._extra = obj.extra || {};
    this._tasks = obj.tasks || {};
    this._creatives = (obj.creatives || []).map((data) => {
      const creative = new Creative();
      creative.fromObject(data);

      return creative;
    });
  }
}