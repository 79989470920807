export default class CreativeConfig {

  constructor(group, templateId, handler) {
    this._group = group;
    this._templateId = templateId;
    this._hdTemplateId = 0;
    this._handler = handler;
    this._isSelected = false;
    this._chanceToSelect = 0;
    this._extra = {};
  }

  get group() {
    return this._group;
  }

  get templateId() {
    return this._templateId;
  }

  get hdTemplateId() {
    return this._hdTemplateId;
  }

  get handler() {
    return this._handler;
  }

  get isSelected() {
    return this._isSelected;
  }

  get chanceToSelect() {
    return this._chanceToSelect;
  }

  get extra() {
    return this._extra;
  }

  setSelected(flag = true) {
    this._isSelected = !!flag;
    return this;
  }

  setHdTemplateId(templateId) {
    this._hdTemplateId = templateId;
    return this;
  }

  setChanceToSelect(chance) {
    this._chanceToSelect = chance;
    return this;
  }

  setExtra(key, value) {
    this._extra[key] = value;
    return this;
  }
}