import React from "react";
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import * as api from "../utils/api";
import {fileToJson} from "../utils/webview";
import Loader from "../components/Loader";
import {generatePath} from "react-router";
import {logEvent, userEvents} from "../utils/log";

export default class UploadPage extends React.Component {
  state = {
    isLoading: true,
    fileUrl: null,
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.file) {
      this.createFile(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  createFile = (file) => {
    if (window.clientConfig.isWebview) {
      const fileEntity = fileToJson(file);

      this.handleFileUploaded(fileEntity);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        fileUrl: e.target.result
      });
    }

    reader.readAsDataURL(file);

    api.createFile(file)
      .then(this.handleFileUploaded)
      .catch((error) => {
        logEvent(userEvents.PHOTO_UPLOAD_FAILED);

        const codeContent = (this.props.location.state && this.props.location.state.codeContent)
          ? this.props.location.state.codeContent : null;

        this.props.history.replace(routes.CREATE, {
          error,
          codeContent,
          step: 2,
        });
      });
  };

  handleFileUploaded = (file) => {
    logEvent(userEvents.PHOTO_UPLOADED, {
      file_id: file.id,
      uploaded_at: Date.now(),
    });

    window.processingManager.clear();

    const codeContent = (this.props.location.state && this.props.location.state.codeContent)
      ? this.props.location.state.codeContent : null;

    const source = (this.props.location.state && this.props.location.state.source)
      ? this.props.location.state.source : null;

    this.props.history.replace({
      pathname: generatePath(routes.PROCESSING),
      state: {
        file,
        codeContent,
        source
      }
    });
  }

  render() {
    return <main className="create-page">
      <div className="container">
        <Loader step={3} />
      </div>
    </main>;
  }
}

UploadPage.contextType = AppContext;