import PhotolabTaskBuilder from "../PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../PhotolabTaskImageUrl";
import {defaultHandlerCatch, defaultHandlerResolver, photolabTask} from "./helpers";

function templateTask(processing, creative) {
  return photolabTask(creative, "template", new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod("gender_classifier"))
      .addImage(new PhotolabTaskImageUrl(processing.file.url, processing.file.rect, processing.file.rotation, processing.file.flip))
      .build());
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export default (processing, creative) => {
  return new Promise((resolve, reject) => {
    templateTask(processing, creative)
      .then((taskResult) => {
        creative.markAsProcessed(taskResult.gender.value);
      })
      .then(defaultHandlerResolver(creative, resolve))
      .catch(defaultHandlerCatch(creative, reject));
  });
}
