export function isValidUrl(str) {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function isEmpty(str) {
  return (!str || /^\s*$/.test(str));
}