import CreativeConfig from "./CreativeConfig";
import {handlersNames} from "./handlers";
import {creativeGroups} from "./groups";
import Creative from "./Creative";

export const creativesConfigs = [
  new CreativeConfig(creativeGroups.COMMON, "gender", handlersNames.GENDER),
  new CreativeConfig(creativeGroups.COMMON, 3410, handlersNames.TEMPLATE),

  new CreativeConfig(creativeGroups.DEFAULT, 6537, handlersNames.VECTOR_YELLOW)
    .setExtra(Creative.EXTRA_QRCODE_STYLES, [9])
    .setExtra(Creative.EXTRA_ALIAS, "vector"),
  new CreativeConfig(creativeGroups.DEFAULT, 6597, handlersNames.DISNEY)
    .setExtra(Creative.EXTRA_QRCODE_STYLES, [9])
    .setExtra(Creative.EXTRA_ALIAS, "disney"),
  new CreativeConfig(creativeGroups.DEFAULT, 6598, handlersNames.GFP936)
    .setExtra(Creative.EXTRA_QRCODE_STYLES, [9])
    .setExtra(Creative.EXTRA_ALIAS, "gfp936"),
  new CreativeConfig(creativeGroups.DEFAULT, 5938, handlersNames.SIMPSONS)
    .setExtra(Creative.EXTRA_QRCODE_STYLES, [9])
    .setExtra(Creative.EXTRA_ALIAS, "simpsons"),
];

export function resolveCreativeConfigByGroup(group) {
  const configs = creativesConfigs.filter((config) => config.group === group);
  let selectedConfig = configs.find((config) => config.isSelected);
  if (!selectedConfig) {
    const sum = configs.reduce((value, config) => value + config.chanceToSelect, 0);
    if (sum === 0) {
      selectedConfig = configs[0];
    } else {
      let chance = Math.random() * sum;
      for (let i = 0; i < configs.length; i++) {
        chance -= configs[i].chanceToSelect;
        if (chance <= 0) {
          selectedConfig = configs[i];
          break;
        }
      }

      if (!selectedConfig) {
        selectedConfig = configs[0];
      }
    }
  }

  return selectedConfig;
}
