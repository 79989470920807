import React from "react";
import PropTypes from "prop-types";
import DumbImageView from "../../components/DumbImageView";
import CreativeErrorView from "./CreativeErrorView";
import CreativeLoaderView from "./CreativeLoaderView";
import {logEvent, userEvents} from "../../utils/log";
import Processing from "../../photolab/Processing";

export default class CreativeView extends React.Component {

  state = {
    isImageLoaded: false,
    tabIndex: 0,
  };

  handleImageLoaded = (creative) => {
    logEvent(userEvents.CREATIVE_VIEW, {
      group: creative.group,
      template_id: creative.templateId,
      is_refresh: creative.isRefreshed,
      code_source: window.processingManager.processing.getExtra(Processing.SOURCE, null),
      code_content: window.processingManager.processing.codeContent,
    });

    this.setState({
      isImageLoaded: true,
    });
  };

  render() {
    const creative = this.props.creative;
    const creativeIsProcessed = creative && creative.isProcessed;
    const creativeIsFailed = creative && creative.isFailed;
    const creativeIsPending = !creativeIsFailed && !creativeIsProcessed && !this.state.isImageLoaded;

    return <React.Fragment>
      {creativeIsProcessed && <DumbImageView
        className="creative"
        image={creative.result[0].url}
        onImageLoaded={() => this.handleImageLoaded(creative)}
      />}

      {creativeIsPending && <CreativeLoaderView {...this.props} />}
      {creativeIsFailed && <CreativeErrorView  {...this.props} />}
    </React.Fragment>;
  }
}

CreativeView.propTypes = {
  creative: PropTypes.object.isRequired,
}