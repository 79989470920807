export function debounce(name, delay, func) {
  window.appDebounceTimers = window.appDebounceTimers || {};
  clearTimeout(window.appDebounceTimers[name]);
  window.appDebounceTimers[name] = setTimeout(func, delay);
}

export function assetUrl(path) {
  const suffix = process.env.REACT_APP_ASSETS_VERSION
    ? ("?" + process.env.REACT_APP_ASSETS_VERSION)
    : "";

  return `${window.appConfig.paths.assets}/${path}${suffix}`;
}

export function pwAssetUrl(path) {
  return `${window.appConfig.paths.pwAssets}/${path}`;
}

export function when(key, map) {
  return map.hasOwnProperty(key) ? map[key] : undefined;
}
