module.exports = {
  "strings": {
    "landing__title": "Turn your boring QR codes into <span>catchy artworks</span>.",
    "landing__text": "No worries, they will still work the same way as your original codes.",
    "landing__try_in_web_button": "TRY WEB APP (beta)",
    "landing__bot_link": "Start QRtoonBot",

    "create__text_step_1": "Scan QR code or paste your link:",
    "create__text_step_2": "Select a photo to be used in QR code",
    "create__text_placeholder": "enter link",
    "create__scan_button": "SCAN QR code",
    "create__gallery_button": "Upload QR code",
    "create__next_button": "next",
    "create__photo_button": "Select Photo",
    "create__enter_link": "Enter a link:",
    "create__or_enter_link": "Or enter a link:",

    "camera__gallery_button": "gallery",

    "loader_processing": "Processing…",

    "result__text_1": "Try this QR code. It works 🙂",
    "result__text_2": "More styles are coming soon.",
    "result__download": "Download",
    "result__choose_photo": "Choose another photo",
    "result__create_new": "Create another QR Toon",
    "result__refresh": "Make it more fun",
    "result__more_styles": "More styles",

    "steps": "Step {{step}}<span>/{{total}}</span>",

    "scan_error__invalid_format": "The selected file is in an invalid format",
    "scan_error__unrecognized_qrcode": "Unrecognized QRcode",
    "scan_error__long_qrcode": "Sorry. Your QR code data is too long",
    "scan_error__invalid_link": "Please enter a valid link",
    "scan_error__comment": "Please, try another picture or enter link",
    "scan_error__unreachable_content_face": "At this step an image with a QR code is expected. Your selfie will be needed later. 😉",

    "internal_error": "An error occurred...",
    "error__default_message": "Something went wrong. Try again.",
    "error__network_message": "Please check your network connection.",
    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "The selected file is in an invalid format",
  },
};

