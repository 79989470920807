import React from "react";
import i18n from "../i18n";

export default function Loader({hidden, step, total}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <div className="loader-page">
    {step && <p className="steps" dangerouslySetInnerHTML={{__html: i18n.t("steps", {step, total})}} />}

    <div className="loader-container">
      <p className="loader-title" dangerouslySetInnerHTML={{__html: i18n.t("loader_processing")}} />
      <div className="loader">        
        <div className="loader-color" />
      </div>
    </div>
  </div>;
}