import templateHandler from "./template.handler";
import genderHandler from "./gender.handler";
import backgroundHandler from "./background.handler";
import vectorYellowHandler from "./vector-yellow.handler";
import disneyHandler from "./disney.handler";
import gfp936Handler from "./gfp936.handler";
import simpsonsHandler from "./simpsons.handler";

export const handlersNames = {
  TEMPLATE: "template",
  GENDER: "gender",
  BACKGROUND: "background",
  VECTOR_YELLOW: "vector_yellow",
  DISNEY: "disney",
  GFP936: "gfp936",
  SIMPSONS: "simpsons",
};

const handlersMap = {
  [handlersNames.TEMPLATE]: templateHandler,
  [handlersNames.GENDER]: genderHandler,
  [handlersNames.BACKGROUND]: backgroundHandler,
  [handlersNames.VECTOR_YELLOW]: vectorYellowHandler,
  [handlersNames.DISNEY]: disneyHandler,
  [handlersNames.GFP936]: gfp936Handler,
  [handlersNames.SIMPSONS]: simpsonsHandler,
};

export function getHandlerByName(name) {
  return handlersMap[name] || null;
}