import * as photolabApi from "../api";
import * as api from "../../utils/api";
import {extractTemplateIdFromXml, PhotolabResponseError} from "../api";
import {PhotolabResponseParseError} from "../api";
import {ApiResponseError} from "../../utils/api";

function safePromisify(value) {
  return value ? Promise.resolve(value) : null;
}

export function promisifyCreativeTask(creative, taskName) {
  return safePromisify(creative.getTask(taskName));
}

function addPhotolabTaskHelper(creative, taskName, taskConfig, withCache = true) {
  if (withCache) {
    const task = promisifyCreativeTask(creative, taskName);
    if (task != null) {
      return task;
    }
  }

  const templateId = extractTemplateIdFromXml(taskConfig);

  return photolabApi.photolabAddTask(taskConfig)
    .then((taskResult) => {
      taskResult.templateId = templateId;
      creative.setTask(taskName, taskResult);

      return taskResult;
    });
}

function waitPhotolabTaskHelper(creative, taskName, taskResult, timeout, interval = 2000) {
  if (taskResult.resultUrl) {
    return Promise.resolve(taskResult);
  } else {
    return photolabApi.photolabWaitTask(taskResult.requestId, timeout, interval)
      .then((result) => {
        result.templateId = taskResult.templateId;

        if (result.resultUrl) {
          result.resultUrl = result.resultUrl.replace("http://", "https://")
        }

        creative.setTask(taskName, result);

        return result;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export function photolabTask(creative, taskName, taskConfig, withCache = true, timeout = 1000, interval = 2000) {
  return addPhotolabTaskHelper(creative, taskName, taskConfig, withCache)
    .then((taskResult) => waitPhotolabTaskHelper(creative, taskName, taskResult, timeout, interval));
}

export function waitTaskHelper(creative, taskName, taskResult, timeout = 0, interval = 1000) {
  return waitTask(taskResult.id, timeout, interval)
    .then((result) => {
      creative.setTask(taskName, result);
      return result;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * @param {Creative} creative
 * @returns {Promise<Creative>}
 */
export function waitCreative(creative) {
  return new Promise((resolve) => {
    if (creative.isFinished) {
      resolve(creative);
    } else {
      setTimeout(() => {
        waitCreative(creative).then(resolve);
      }, 250);
    }
  });
}

export function defaultHandlerResolver(creative, resolve) {
  return () => {
    resolve(creative);
  };
}

export function defaultHandlerCatch(creative, reject) {
  return (err) => {
    console.error(err, err.parentError);

    const type = "internal";
    const errorState = {
      type,
      name: err.name,
      code: err.code,
      message: err.message,
    };

    if (err instanceof PhotolabResponseError || err instanceof PhotolabResponseParseError) {
      errorState.type = "photolab";
    } else if (err instanceof ApiResponseError) {
      errorState.type = "api";
    } else if (err instanceof ApiTaskError) {
      errorState.type = "api_task";
    } else if (err instanceof HandlerCancelError) {
      if (err.parentError !== undefined) {
        errorState.type = err.parentError.type;
        errorState.name = err.parentError.name;
        errorState.code = err.parentError.code;
        errorState.message = err.parentError.message;
      } else {
        errorState.type = "handler_cancel";
      }
    }

    creative.markAsFailed(errorState);

    reject(creative);
  };
}

class ApiTaskError extends Error {
  constructor(task) {
    super();
    this.name = "ApiTaskError";
    this.code = -1;
    this.message = task.result && task.result.reason;
  }
}

export class HandlerCancelError extends Error {
  constructor(message, parentError) {
    super();
    this.name = "HandlerCancelError";
    this.code = -1;
    this.message = message;
    this.parentError = parentError;
  }
}

export function waitTask(taskId, timeout = 0, interval = 1000, requestsAmount = 0) {
  requestsAmount++;

  function _call(resolve, reject) {
    api.fetchTask(taskId).then((task) => {
      if (task.status === 1) {
        task.getResultRequestsAmount = requestsAmount;
        resolve(task);
      } else if (task.status === -1) {
        throw new ApiTaskError(task);
      } else {
        setTimeout(() => {
          waitTask(taskId, 0, interval).then(resolve).catch(reject);
        }, interval || 1000);
      }
    }).catch((error) => {
      error.getResultRequestsAmount = requestsAmount;
      reject(error);
    });
  }

  return new Promise((resolve, reject) => {
    if (timeout === 0) {
      _call(resolve, reject);
    } else {
      setTimeout(() => _call(resolve, reject), timeout);
    }
  });
}
