import React from "react";

export function ExternalUrl({className, url, children, onClick, hidden, dangerouslySetInnerHTML}) {
  if (hidden) {
    return null;
  }

  return <a
    href={url}
    className={className}
    onClick={onClick}
    children={children}
    target="_blank"
    rel="noopener noreferrer" />;
}
