import Modal from "./Modal";
import React from "react";
import AppContext from "../contexts/AppContext";

export default class ErrorModal extends Modal {

  constructor(props) {
    super(props);
    this.className = ""; // todo
  }

  handleClick = (e) => {
    this.props.onClick && this.props.onClick(e);

    this.dismiss();
  }

  renderModal() {
    return <div className="modal-container">
      <div className="modal">
        {this.props.title && <h2 children={this.props.title} />}
        {this.props.text && <p children={this.props.text} />}
        <button className="btn-upload-foto" children={"ok"} onClick={this.handleClick} />
      </div>
    </div>
  }
}

ErrorModal.contextType = AppContext;
