import React from "react";
import i18n from "../../i18n";
import routes from "../../routes";
import AppContext from "../../contexts/AppContext";
import {debounce} from "../../utils/etc";
import TabContentView from "./TabContentView";
import Loader from "../../components/Loader";
import {creativeGroups} from "../../photolab/groups";
import {logEvent, userEvents} from "../../utils/log";
import {creativesConfigs} from "../../photolab/config";
import Creative from "../../photolab/Creative";

export default class ResultPage extends React.Component {
  state = {
    isLoading: true,
    groups: [
      creativeGroups.DEFAULT,
    ],
    group: creativeGroups.DEFAULT,
  };

  loadedUrl = new URL(window.location.href);

  componentDidMount() {
    logEvent(userEvents.PAGE_RESULT);

    window.processingManager.addOnProcessingChangeHandler(this.handleProcessingChangedDebug);
    window.processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    if (window.processingManager.processing === null) {
      const restoredProcessing = window.processingManager.restore();
      if (restoredProcessing) {
        window.processingManager.start(restoredProcessing);
      } else {
        this.props.history.replace(routes.INDEX);
        return;
      }
    }

    this.handleProcessingChanged();
  }

  componentWillUnmount() {
    window.processingManager.removeOnProcessingChangeHandler(this.handleProcessingChangedDebug);
    window.processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);
  }

  handleProcessingChangedDebug = () => {
    if (window.appConfig.isDebug) {
      const cloned = JSON.parse(window.processingManager.processing.toJSON(true));
      console.info("ResultPage::handleProcessingChanged", cloned);
    }
  };

  handleProcessingChanged = () => debounce("ResultPage_handleProcessingChanged", 100, () => {
    const selectedCreative = window.processingManager.processing.getSelectedCreativeInGroup(this.state.group);

    if (selectedCreative.isFinished) {
      this.setState({
        isLoading: false,
      });
    }
  });

  handleRestart = () => {
    this.props.history.replace(routes.CREATE, {
      codeContent: window.processingManager.processing.codeContent,
    });
  };

  handleFileSelected = (file) => {
    this.props.history.replace(routes.UPLOAD, {
      file: file,
      codeContent: window.processingManager.processing.codeContent,
    });
  };

  handleRefresh = () => {
    logEvent(userEvents.REFRESH);

    this.setState({
      isLoading: true,
    });

    const groupCreatives = window.processingManager.processing.getCreativesInGroup(this.state.group);
    const groupConfigs = creativesConfigs.filter((config) => config.group === this.state.group);
    const unusedConfigs = groupConfigs.filter((config) => !groupCreatives.find((c) => c.templateId === config.templateId));
    const selectedCreative = window.processingManager.processing.getSelectedCreativeInGroup(this.state.group);

    selectedCreative.setAsSelected(false);

    if (unusedConfigs.length > 0) {
      const nextConfig = unusedConfigs.first();

      window.processingManager.processing.addCreative(new Creative()
        .configureByConfig(nextConfig)
        .setAsSelected(true)
        .setAsRefreshed(true));

      window.processingManager.update();
    } else {
      const currentIndex = groupCreatives.findIndex((c) => c.id === selectedCreative.id);
      const nextCreative = groupCreatives.find((_, index) => index > currentIndex);

      if (nextCreative) {
        nextCreative.setAsSelected(true);
      } else {
        groupCreatives[0].setAsSelected(true);
      }

      this.handleProcessingChanged();
    }
  }

  render() {
    const steps = this.loadedUrl.searchParams.get("ref") === "webtemplate"
      ? {step: 2, total: 2}
      : {step: 3, total: 3};

    if (this.state.isLoading) {
      return <main className="create-page">
        <div className="container">
          <Loader {...steps} />
        </div>
      </main>;
    }

    return <main className="result-page">
      <div className="container">
        <p className="steps" dangerouslySetInnerHTML={{__html: i18n.t("steps", steps)}} />
        <p className="page-subtitle" dangerouslySetInnerHTML={{__html: i18n.t("result__text_1")}} />
        <p className="page-title" dangerouslySetInnerHTML={{__html: i18n.t("result__text_2")}} />

        <TabContentView
          group={this.state.group}
          onFileSelected={this.handleFileSelected}
          onRefreshClick={this.handleRefresh}
          onRestartClick={this.handleRestart} />
      </div>
    </main>;
  }
}

ResultPage.contextType = AppContext;

