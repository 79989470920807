import * as amplitude from "amplitude-js";

const userProps = {
  is_webview: window.clientConfig.isWebview,
  split_group: window.clientConfig.splitGroupId,
};

if (window.clientConfig.loadedUrlParams["ref"]) {
  userProps.ref = window.clientConfig.loadedUrlParams["ref"];
}

if (window.clientConfig.loadedUrlParams["utm_source"]) {
  userProps.utm_source = window.clientConfig.loadedUrlParams["utm_source"];
}

const userPropertiesCommitWaited = Object.keys(userProps);

if (window.appConfig.isDebug) {
  console.log("initial user properties", userProps);
}

if (window.appConfig.amplitude.isEnabled) {
  amplitude.getInstance().init(window.appConfig.amplitude.key);
  amplitude.getInstance().setUserProperties({...userProps});
}

// --

export const userEvents = {
  // визит на главную страницу
  PAGE_INDEX: "page_index",

  // визит на страницу создания
  PAGE_CREATE: "page_create",

  // визит на страницу результата
  PAGE_RESULT: "page_result",

  // юзер нажал на "попробовать другое фото"
  // параметры: page (страница: create, result, error)
  HOME_BUTTON_CLICK: "home_button_click",

  QRCODE_SCAN_SUCCESS: "qrcode_scan_success",
  QRCODE_SCAN_FAIL: "qrcode_scan_fail",

  PHOTO_SELECT: "photo_select",
  PHOTO_UPLOADED: "photo_uploaded",
  PHOTO_UPLOAD_FAILED: "photo_upload_failed",

  PROCESSING_PROCESSED: "processing_processed",
  PROCESSING_FAILED: "processing_failed",

  CREATIVE_STARTED: "creative_started",
  CREATIVE_PROCESSED: "creative_processed",
  CREATIVE_FAILED: "creative_failed",

  CREATIVE_VIEW: "creative_view",

  DOWNLOAD: "download",

  REFRESH: "refresh",
};

export const hits = {
  TAB_OPENED: 0,

  PHOTO_SELECT: 0,
  PHOTO_UPLOADED: 0,
  PHOTO_UPLOAD_FAILED: 0,

  PROCESSING_PROCESSED: 0,
  PROCESSING_FAILED: 0,
  PROCESSING_FAILED_PHOTOLAB: 0,

  SHARE: 0,
  DOWNLOAD: 0,
};

export function hitEvent(id) {
  if (id === 0) {
    return;
  }

  if (window.appConfig.isDebug) {
    console.info("hitEvent", id);
  }

  if (window.appConfig.hits.isEnabled) {
    window.axios.post(`${window.appConfig.hits.endpoint}?id=${id}&r=${Date.now()}`)
      .then(() => {/* dummy */})
      .catch(console.error);
  }
}

export function logEvent(eventId, eventParams, cb) {
  if (window.appConfig.isDebug) {
    console.info("logEvent", eventId, eventParams);
  }

  logAmplitude(eventId, eventParams, cb);

  if (window.appConfig.analytics.isEnabled) {
    let userParams = undefined;
    if (userPropertiesCommitWaited.length > 0) {
      userParams = {};
      userPropertiesCommitWaited.forEach((key) => userParams[key] = userProps[key]);
      userPropertiesCommitWaited.length = 0;
    }

    window.axios.post(window.appConfig.analytics.endpoint, {
      id: eventId,
      params: eventParams,
      user_params: userParams,
    })
      .then(() => {/* dummy */})
      .catch(console.error);
  }
}

export function logAmplitude(eventId, eventParams, cb) {
  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().logEvent(eventId, eventParams || {}, cb);
  }
}

export function setUserProperty(key, value) {
  if (window.appConfig.isDebug) {
    console.log("setUserProperty", key, value);
  }

  const currentValue = userProps[key];
  if (currentValue !== value) {
    userProps[key] = value;
    userPropertiesCommitWaited.push(key);
  }

  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().identify(new amplitude.Identify().set(key, value));
  }
}