export function containRectToBounds(rect, bounds) {
  const rectRatio = rect.width / rect.height;
  const boundsRatio = bounds.width / bounds.height;
  const box = {};

  if (rectRatio > boundsRatio) {
    box.width = bounds.width;
    box.height = rect.height * (bounds.width / rect.width);
  } else {
    box.width = rect.width * (bounds.height / rect.height);
    box.height = bounds.height;
  }

  box.x = (bounds.width - box.width) / 2;
  box.y = (bounds.height - box.height) / 2;
  box.scale = bounds.width / rect.width;

  return box;
}