import React from "react";
import i18n from "../../i18n";
import FileChooseButton from "../../components/FileChooseButton";

export default function SecondStepView({onFileSelected}) {
  return <main className="create-page">
    <div className="container">
      <p className="steps" dangerouslySetInnerHTML={{__html: i18n.t("steps", {step: 2, total: 3})}} />
      <p className="page-subtitle" dangerouslySetInnerHTML={{__html: i18n.t("create__text_step_2")}} />
      <div className="btns-container">
        <FileChooseButton
          onFileSelected={onFileSelected}
          className="btn btn-selection"
          children={i18n.t("create__photo_button")} />
      </div>
    </div>
  </main>;
}