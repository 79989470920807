import {getLocationQueryObject} from "./utils/text";
import clientStorage from "./utils/client-storage";
import {detect} from "detect-browser";
import {generateHash} from "./utils/text";
import {androidRequestParams, iosRequestParams} from "./utils/constants";

let query = getLocationQueryObject();
const platformInfo = detect();
const platformIsMobile = ["android", "android os", "ios"].indexOf(platformInfo.os.toLowerCase()) > -1;
let isWebview = typeof query["aid"] !== "undefined" || typeof query["vicman_unified_id"] !== "undefined";
let isWebviewMock = false;

if (isWebview) {
  clientStorage.setWebviewRequestParams(query);
}

if (!isWebview && window.location.hostname === process.env.REACT_APP_CDN_HOSTNAME) {
  let requestParams = clientStorage.getWebviewRequestParams();

  isWebview = true;
  isWebviewMock = true;

  if (Object.keys(requestParams).length === 0) {
    if (["ios"].indexOf(platformInfo.os.toLowerCase()) > -1) {
      requestParams = iosRequestParams;
      requestParams.vicman_unified_id = generateHash();
    } else {
      requestParams = androidRequestParams;
      requestParams.aid = generateHash();
    }

    clientStorage.setWebviewRequestParams(requestParams);
  }

  query = {...requestParams, ...query};
}

window.clientConfig = {
  platform: platformInfo,
  isWeb: !isWebview,
  isWebMobile: !isWebview && platformIsMobile,
  isWebDesktop: !isWebview && !platformIsMobile,
  isWebview: isWebview,
  isWebviewAndroid: typeof query["aid"] !== "undefined",
  isWebviewIOS: typeof query["vicman_unified_id"] !== "undefined",
  isWebviewMock: isWebviewMock,
  isAnyMobile: isWebview || platformIsMobile,
  webviewParams: isWebview ? query : {},
  isPro: query["is_pro"] === "1",
  locale: query["locale"] || query["lang"] || window.navigator.language || undefined,
  splitGroupId: undefined,
  loadedUrlParams: query,
};

window.clientConfig.lang = window.clientConfig.locale
  ? window.clientConfig.locale.substring(0, 2).toLowerCase()
  : undefined;

// ---

if (window.clientConfig.isWebview) {
  window.clientConfig.token = window.clientConfig.isWebviewAndroid
    ? `w:a:${query["aid"]}`
    : `w:i:${query["vicman_unified_id"]}`;
} else {
  const clientToken = query["client_token"] || clientStorage.getClientToken();
  if (clientToken) {
    window.clientConfig.token = clientToken;
  } else {
    const type = platformIsMobile ? "m" : "d";
    const hash = generateHash();

    window.clientConfig.token = `b:${type}:${hash}`;
    clientStorage.setClientToken(window.clientConfig.token);
  }
}

// ---

if (window.clientConfig.isWebview) {
  const aid = query["aid"] || query["vicman_unified_id"];
  const num = parseInt(aid.substring(aid.length - 2), 16);
  const group = Math.floor(num / (255 / 10)) + 1;

  window.clientConfig.splitGroupId = Math.min(group, 10); // fix for ff => 11 group
} else {
  const groupId = query["split_group"] || clientStorage.getSplitGroupId();
  if (groupId) {
    window.clientConfig.splitGroupId = parseInt(groupId);
  } else {
    window.clientConfig.splitGroupId = Math.floor(Math.random() * 10) + 1;
  }

  clientStorage.setSplitGroupId(window.clientConfig.splitGroupId);
}

// ---

window.appConfig = {
  projectKey: process.env.REACT_APP_PROJECT_KEY,

  clientName: process.env.REACT_APP_CLIENT_NAME || "",

  isDebug: process.env.NODE_ENV.startsWith("prod") === false
    || process.env.REACT_APP_DEBUG === "true"
    || query["vicman_debug"]
    || false,

  paths: {
    app: process.env.REACT_APP_APP_PATH,
    assets: process.env.REACT_APP_ASSETS_PATH,
    pwAssets: process.env.REACT_APP_PW_ASSETS_PATH,
    api: process.env.REACT_APP_API_PATH,
    apiUpload: process.env.REACT_APP_API_FILES_PATH,
    apiSign: process.env.REACT_APP_API_SIGN_PATH,
  },

  photolab: {
    appId: process.env.REACT_APP_PHOTOLAB_APP_ID,
    path: process.env.REACT_APP_PHOTOLAB_API_PATH,
  },

  amplitude: {
    isEnabled: process.env.REACT_APP_AMPLITUDE_ENABLED === "true",
    key: process.env.REACT_APP_AMPLITUDE_KEY,
  },

  analytics: {
    isEnabled: process.env.REACT_APP_ANALYTICS_ENABLED === "true",
    endpoint: process.env.REACT_APP_ANALYTICS_ENDPOINT,
  },

  hits: {
    isEnabled: process.env.REACT_APP_HITS_ENABLED === "true",
    endpoint: process.env.REACT_APP_HITS_ENDPOINT,
  },

  sentry: {
    isEnabled: process.env.REACT_APP_SENTRY_ENABLED === "true",
    dsn: process.env.REACT_APP_SENTRY_DSN || "",
    sampleRate: 1,
  },

  googleAnalytics: {
    isEnabled: (process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === "true") && window.clientConfig.isWeb,
    trackerId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKER_ID,
  },

  telegram: {
    botUsername: process.env.REACT_APP_TELEGRAM_BOT_USERNAME,
  },
};
