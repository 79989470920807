import * as api from "../utils/api";
import axios from "axios";
import axiosRetry from "axios-retry";
import PhotolabTaskBuilder from "./PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "./PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "./PhotolabTaskCollageMethod";

const xmlParser = new DOMParser();
const httpClient = axios.create({
  baseURL: window.appConfig.photolab.path,
});

axiosRetry(httpClient, {
  retries: 3,
  retryDelay: 1000,
});

export class PhotolabResponseError extends Error {
  constructor(xmldoc, requestId = undefined) {
    super();
    this.name = "PhotolabResponseError";
    this.requestId = requestId;
    this.code = parseInt(xmldoc.documentElement.querySelector("err_code").textContent);
    this.message = xmldoc.documentElement.querySelector("description").textContent;
  }
}

export class PhotolabResponseParseError extends Error {
  constructor(xmldoc) {
    super();
    this.name = "PhotolabResponseParseError";
    this.code = -1;
    this.message = `Error XML response parsing`;
    // todo extract error message from xmldoc
  }
}

export class PhotolabGetResultTimeoutError extends Error {
  constructor(taskId, amounts) {
    super();
    this.name = "PhotolabGetResultTimeoutError";
    this.requestId = taskId;
    this.code = -1;
    this.message = `Max attempts for getResult task is reached (${amounts})`;
    this.amounts = amounts;
  }
}

function checkError(err) {
  throw err;
}

function addTask(data, signData) {
  const formData = new FormData();
  formData.append("app_id", window.appConfig.photolab.appId);
  formData.append("data", data);
  formData.append("sign_data", signData);

  const requestTimeAt = Date.now();

  return httpClient.post("/addtask", formData)
    .then((res) => {
      const responseTimeAt = Date.now();
      const xmldoc = xmlParser.parseFromString(res.data, "application/xml");

      if (xmldoc.documentElement.nodeName === "parsererror") {
        throw new PhotolabResponseParseError(xmldoc);
      }

      const errorCodeNode = xmldoc.documentElement.querySelector("err_code");

      if (errorCodeNode !== null && errorCodeNode.textContent !== "0") {
        throw new PhotolabResponseError(xmldoc);
      }

      return {
        requestId: xmldoc.documentElement.querySelector("request_id").textContent,
        status: xmldoc.documentElement.querySelector("status").textContent,
        errorCode: xmldoc.documentElement.querySelector("err_code").textContent,
        description: xmldoc.documentElement.querySelector("description").textContent,
        addTaskRequestAt: requestTimeAt,
        addTaskResponseAt: responseTimeAt,
      };
    })
    .catch(checkError);
}

function getResultTask(taskId) {
  return httpClient.post("/getresult?request_id=" + taskId + "&r=" + Math.random())
    .then((res) => {
      const xmldoc = xmlParser.parseFromString(res.data, "application/xml");

      if (xmldoc.documentElement.nodeName === "parsererror") {
        throw new PhotolabResponseParseError(xmldoc);
      }

      if (xmldoc.documentElement.querySelector("err_code") !== null) {
        throw new PhotolabResponseError(xmldoc, taskId);
      }

      const status = xmldoc.documentElement.querySelector("status").textContent;
      const response = {
        requestId: xmldoc.documentElement.querySelector("request_id").textContent,
        status: status,
      };

      if (status === "OK") {
        const resultUrlNode = xmldoc.documentElement.querySelector("result_url");
        if (resultUrlNode) {
          response.resultUrl = resultUrlNode.textContent;
        }

        response.duration = xmldoc.documentElement.querySelector("duration").textContent;
        response.totalDuration = xmldoc.documentElement.querySelector("total_duration").textContent;

        const genderNode = xmldoc.documentElement.querySelector("gender");
        if (genderNode) {
          response.gender = {
            value: genderNode.querySelector("value").textContent,
            probability: parseFloat(genderNode.querySelector("probability").textContent),
          };
        }

        const resultsNode = xmldoc.documentElement.querySelector("results");
        if (resultsNode) {
          response.results = [];

          resultsNode.childNodes.forEach((resultNode) => {
            response.results.push({
              templateId: parseInt(resultNode.getAttribute("template_name")),
              resultUrl: resultNode.textContent,
            });
          });
        }
      }

      return response;
    })
    .catch(checkError);
}

export function photolabGenderTask(imageUrl) {
  const taskConfig = new PhotolabTaskBuilder()
    .addImage(new PhotolabTaskImageUrl(imageUrl))
    .addMethod(new PhotolabTaskCollageMethod("gender_classifier"))
    .build();

  return photolabAddTask(taskConfig)
    .then((taskResult) => photolabWaitTask(taskResult.requestId, 1000, 250));
}

export function photolabTask(taskConfig, timeout = 1000, interval = 1000) {
  return photolabAddTask(taskConfig)
    .then((taskResult) => photolabWaitTask(taskResult.requestId, timeout, interval));
}

export function photolabAddTask(taskXmlConfig) {
  return new Promise((resolve, reject) => {
    api.photolabSign(taskXmlConfig)
      .then((signTaskResult) => addTask(taskXmlConfig, signTaskResult.data))
      .then(resolve)
      .catch(reject);
  });
}

export function photolabWaitTask(taskId, timeout = 0, interval = 1000, requestsAmount = 0, requestsAmountMax = 0) {
  requestsAmount++;

  function _call(resolve, reject) {
    getResultTask(taskId).then((taskResult) => {
      if (taskResult.status === "OK") {
        taskResult.getResultRequestsAmount = requestsAmount;
        resolve(taskResult);
      } else {
        if (requestsAmountMax > 0 && requestsAmount === requestsAmountMax) {
          reject(new PhotolabGetResultTimeoutError(taskId, requestsAmountMax));
        } else {
          setTimeout(() => {
            photolabWaitTask(taskId, 0, interval, requestsAmount, requestsAmountMax).then(resolve).catch(reject);
          }, interval || 1000);
        }
      }
    }).catch((error) => {
      error.getResultRequestsAmount = requestsAmount;
      reject(error);
    });
  }

  return new Promise((resolve, reject) => {
    if (timeout === 0) {
      _call(resolve, reject);
    } else {
      setTimeout(() => _call(resolve, reject), timeout);
    }
  });
}

export function extractTemplateIdFromXml(taskConfig) {
  const xmlConfig = xmlParser.parseFromString(taskConfig, "application/xml");
  let templateId = xmlConfig.documentElement.querySelector("methods_list");
  templateId = templateId ? templateId.firstChild.querySelector("params") : null;
  templateId = templateId ? /template_name=([^;]+)/.exec(templateId.textContent) : null;

  return templateId ? templateId[1] : null;
}
