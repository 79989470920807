import React from "react";
import AppContext from "../contexts/AppContext";
import i18n from "../i18n";
import Loader from "../components/Loader";
import jsQR from "jsqr";
import routes from "../routes";
import * as api from "../utils/api";
import {containRectToBounds} from "../utils/geometry";
import FileChooseButton from "../components/FileChooseButton";
// eslint-disable-next-line no-unused-vars
import adapter from "webrtc-adapter";
import {checkDataSize, transformData} from "../utils/qrcode";
import ErrorModal from "../components/ErrorModal";
import {waitTask} from "../photolab/handlers/helpers";
import {logEvent, userEvents} from "../utils/log";
import {generatePath} from "react-router";

const botLink = "https://t.me/" + window.appConfig.telegram.botUsername;
const INITIAL_CONSTRAINTS_SIZES = [1080, 720, 640, 480];
const STOP_CAMERA_INTERVAL = 5000;
const SCAN_INTERVAL = 100;

export default class QRCodeCameraPage extends React.Component {

  state = {
    isLoading: true,
    error: null,
    data: null,
    finderRectBounds: null,
    isFromTelegram: false,
  };

  constructor(props) {
    super(props);
    this.captureDevice = null;
    this.videoRef = null;
    this.videoStreamURL = null;
    this.constraints = INITIAL_CONSTRAINTS_SIZES;
    this.shadowCanvas = document.createElement("canvas");
    this.scanIsEnabled = true;
    this.scanTries = 0;
  }

  componentDidMount() {
    this.setState({
      isFromTelegram: window.location.href.indexOf("tgbot=1") > 0,
    });

    this.startCamera();
  }

  componentWillUnmount() {
    this.stopCamera();
  }

  startCamera = () => {
    clearTimeout(this.stopCameraTimer);

    this.setState({isLoading: true}, () => {
      if (typeof window.navigator.mediaDevices === "undefined"
        || !window.navigator.mediaDevices.getUserMedia) {
        const err = new Error("WEBRTC not supported");
        err.name = "NotSupported";
        this.handleError(err, "camera");
        return;
      }

      const requestSize = this.constraints.shift();
      const videoConstraints = window.isWebviewIOS
        ? {width: requestSize, height: requestSize}
        : {width: {min: requestSize}, height: {min: requestSize}};

      videoConstraints.facingMode = "environment";

      this.stopCameraTimer = setTimeout(() => {
        const err = new Error("Timeout was reached");
        err.name = "NotSupported";

        this.handleError(err, "timeout");
      }, STOP_CAMERA_INTERVAL);

      // try to catch 'then of undefined'
      try {
        window.navigator.mediaDevices.getUserMedia({video: videoConstraints})
          .then(this.handleCameraSuccess)
          .catch((err) => {
            if ((err.name === "OverconstrainedError" || err.name === "NotReadableError") && this.constraints.length > 0) {
              this.startCamera();
            } else {
              window.navigator.mediaDevices.getUserMedia({video: true})
                .then(this.handleCameraSuccess)
                .catch((err) => {
                  this.handleError(err, "camera");
                });
            }
          });
      } catch(e) {
        const err = new Error("StartCamera error");
        err.name = "NotSupported";
        this.handleError(err, "camera");
      }
    });
  };

  stopCamera = () => {
    clearTimeout(this.stopCameraTimer);
    clearInterval(this.scanInterval);

    if (this.captureDevice) {
      this.captureDevice.stop();
      this.captureDevice = null;
    }

    if (this.videoStreamURL) {
      URL.revokeObjectURL(this.videoStreamURL);
    }
  };

  handleError = (err, source, force = true) => {
    console.log(err, source);

    this.setState({isLoading: false, error: err});

    this.context.pushModal(<ErrorModal
      key="CreatePage_MessageModal"
      text={err.message}
      onClick={(e) => {
        force && !this.state.isFromTelegram && this.props.history.replace({
          pathname: routes.CREATE,
          search: window.location.search,
        });
      }}/>);

    force && this.stopCamera();
  };

  handleCameraSuccess = (stream) => {
    clearTimeout(this.stopCameraTimer);

    this.setState({
      isLoading: false,
      error: null,
    }, () => {
      this.captureDevice = stream.getVideoTracks()[0];

      if (window.HTMLMediaElement) { // ios 11 fix
        this.videoRef.srcObject = stream;
      } else {
        this.videoStreamURL = URL.createObjectURL(stream);
        this.videoRef.src = this.videoStreamURL;
      }

      this.constraints = INITIAL_CONSTRAINTS_SIZES;
      this.scanInterval = setInterval(this.handleScanFrame, SCAN_INTERVAL);
    });
  };

  handleScanFrame = () => {
    if (this.videoRef.videoWidth < 1 || this.videoRef.videoHeight < 1) {
      return; // video steam isn't available
    }

    if (!this.scanIsEnabled) {
      return;
    }

    this.scanTries++;

    const frameMod = 0.5;
    const finderVisibleSize = Math.round(
      Math.min(this.videoRef.clientWidth, this.videoRef.clientHeight) * frameMod
    );

    const videoBounds = containRectToBounds(
      {width: this.videoRef.videoWidth, height: this.videoRef.videoHeight},
      {width: this.videoRef.clientWidth, height: this.videoRef.clientHeight}
    );

    let frameSize;
    if (videoBounds.x > 0) {
      frameSize = Math.round(this.videoRef.videoHeight * frameMod);
    } else if (videoBounds.y > 0) {
      frameSize = Math.round(this.videoRef.videoWidth * frameMod)
    } else {
      frameSize = Math.round(
        Math.min(this.videoRef.videoWidth, this.videoRef.videoHeight) * frameMod
      );
    }

    this.setState({
      finderRectBounds: {
        width: finderVisibleSize,
        height: finderVisibleSize,
      },
    });

    const shadowCtx = this.shadowCanvas.getContext("2d");
    const sx = Math.floor(this.videoRef.videoWidth/2 - frameSize/2);
    const sy = Math.floor(this.videoRef.videoHeight/2 - frameSize/2);
    const scaledSize = this.scanTries % 4 === 0 ? frameSize : 480;

    this.shadowCanvas.width = scaledSize;
    this.shadowCanvas.height = scaledSize;
    shadowCtx.drawImage(this.videoRef, sx, sy, frameSize, frameSize, 0, 0, scaledSize, scaledSize);
    const imageData = shadowCtx.getImageData(0, 0, scaledSize, scaledSize).data;

    const result = jsQR(imageData, scaledSize, scaledSize);
    if (result) {
      this.handleScannedData(result, "camera");
    }
  };

  handleScannedData = (result, source) => {
    this.scanIsEnabled = false;

    const data = transformData(result.data);

    if (!checkDataSize(data)) {
      logEvent(userEvents.QRCODE_SCAN_FAIL, {
        source: source,
        reason: "long_data",
      });

      const err = new Error(i18n.t("scan_error__long_qrcode"));
      this.handleError(err, "data", false);

      return;
    }

    logEvent(userEvents.QRCODE_SCAN_SUCCESS, {
      source: source,
      data: data,
    });

    if (this.state.isFromTelegram) {
      api.telegramPutQRCodeData(data).then(() => {
        this.returnToBotChat();
      }).catch((err) => {
        this.handleError(err, "api");
      })
    } else {
      if (this.loadedUrl.searchParams.get("file_url")) {
        window.processingManager.clear();

        this.props.history.replace({
          pathname: generatePath(routes.PROCESSING),
          state: {
            codeContent: data,
            source,
          },
          search: window.location.search
        });

        return;
      }

      this.props.history.replace({
        pathname: routes.CREATE,
        state: {
          codeContent: data,
          source: source,
          step: 2,
        }
      });
    }
  };

  returnToBotChat = () => {
    window.location.href = botLink;
    // if (window.clientConfig.isWebMobile) {
    //   if (window.clientConfig.platform.os.toLowerCase() === "ios") {
    //
    //   } else {
    //     // todo check is in-app browser
    //     window.close();
    //   }
    // } else {
    //   window.close();
    // }
  };

  handleGalleryButtonClick = (file) => {
    this.setState({
      isLoading: true,
    });

    const isPDF = file.type === "application/pdf";
    const createFile = isPDF ? api.createPDF : api.createFile;

    createFile(file)
      .then((file) => api.createTask("qrcode_scan", {content_url: file.url}))
      .then((taskResult) => waitTask(taskResult.id))
      .then((taskResult) => {
        this.handleScannedData({data: taskResult.result}, (isPDF ? "pdf" : "image"));
      })
      .catch((error) => {
        console.log(error);

        logEvent(userEvents.QRCODE_SCAN_FAIL, {
          source: isPDF ? "pdf" : "image",
          reason: error.message,
        });

        const text = error.message === "unreachable_content_face"
          ? i18n.t("scan_error__unreachable_content_face")
          : i18n.t("scan_error__comment");

        this.handleError(new Error(text), "gallery");
      });
  };

  handleBackButtonClick = () => {
    if (this.state.isFromTelegram) {
      this.returnToBotChat();
    } else {
      this.props.history.replace({
        pathname: routes.CREATE,
        search: window.location.search,
      });
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    return <main className="main-page qr-page">
      <div className="container">
        <div className="video-container">
          <video
            ref={(ref) => this.videoRef = ref}
            playsInline
            autoPlay
            muted
            controls={false} />
          {this.state.finderRectBounds && <div
            style={this.state.finderRectBounds}
            className="finder-rect" />}
        </div>
      </div>
      <div className="btns-container">
        <button
          className="btn-circle">
          <svg viewBox="0 0 13 24">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.825 10.313 2.175 24l3.338-10.313H0L10.65 0 7.313 10.313h5.512z" fill="#fff"/>
          </svg>
        </button>

        <FileChooseButton
          onFileSelected={this.handleGalleryButtonClick}
          accept="image/*,application/pdf"
          className="btn-qr-gallery"
          children={i18n.t("camera__gallery_button")} />

        <button
          className="btn-circle"
          onClick={this.handleCameraButtonClick}>
          <svg viewBox="0 0 29 24">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.47 21.11c-4.681 0-8.512-3.832-8.755-8.607h3.04L4.438 6.597 0 12.502h2.98C3.222 18.848 8.33 24 14.53 24c2.797 0 5.351-1.068 7.357-2.764l-1.946-2.073c-1.58 1.193-3.404 1.947-5.47 1.947zm0-21.11c-2.858 0-5.533 1.13-7.539 2.89L8.877 4.9a8.46 8.46 0 0 1 5.593-2.073c4.681 0 8.511 3.833 8.754 8.67h-3.16L24.5 17.53 29 11.56h-3.04C25.778 5.152 20.732 0 14.47 0z" fill="#fff"/>
          </svg>
        </button>
      </div>

      <button
        className="btn-back"
        onClick={this.handleBackButtonClick}>
        <svg viewBox="0 0 25 16" fill="none">
          <path d="M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293zM25 7H1v2h24V7z" fill="#fff"/>
        </svg>
      </button>
    </main>;
  }
}

QRCodeCameraPage.contextType = AppContext;

