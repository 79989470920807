import React from "react";
import i18n from "../i18n";
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import {fileToJson} from "../utils/webview";
import {creativesConfigs, resolveCreativeConfigByGroup} from "../photolab/config";
import {creativeGroups, sitesGroups} from "../photolab/groups";
import Processing from "../photolab/Processing"
import Creative from "../photolab/Creative";
import Loader from "../components/Loader";
import {logEvent, userEvents} from "../utils/log";

export default class ProcessingPage extends React.Component {
  loadedUrl = new URL(window.location.href);

  componentDidMount() {
    window.processingManager.addOnProcessingChangeHandler(this.handleProcessingChange);

    const fileUrlParam = this.loadedUrl.searchParams.get("file_url");
    const codeContentParam = this.loadedUrl.searchParams.get("code_content");

    const file = fileUrlParam ? fileToJson(decodeURIComponent(fileUrlParam))
      : (this.props.location.state && this.props.location.state.file ? this.props.location.state.file : null);

    file.rect = this.loadedUrl.searchParams.get("rect") || "";
    file.rotation = this.loadedUrl.searchParams.get("rotation") || 0;
    file.flip = this.loadedUrl.searchParams.get("flip") || 0;

    const codeContent = codeContentParam ? decodeURIComponent(codeContentParam)
      : (this.props.location.state && this.props.location.state.codeContent ? this.props.location.state.codeContent : null);
    const source = (this.props.location.state && this.props.location.state.source)
      ? this.props.location.state.source : null;

    const processing = window.processingManager.restore();

    if (processing) {
      window.processingManager.start(processing);
    } else if (file) {
      this.startProcessing(file, codeContent, source);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {
    window.processingManager.removeOnProcessingChangeHandler(this.handleProcessingChange);
  }

  startProcessing = (file, codeContent, source) => {
    window.processingManager.clear();

    const groups = sitesGroups.slice();

    const processing = new Processing();
    processing.setFile(file);
    processing.setCodeContent(codeContent);
    processing.setGroups(groups);
    processing.setLanguage(window.clientConfig.lang);
    processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
    processing.setExtra(Processing.SOURCE, source);

    creativesConfigs.filter((config) => config.group === creativeGroups.COMMON)
      .forEach((config) => {
        processing.addCreative(new Creative()
          .configureByConfig(config));
      });

    const selectedConfig = creativesConfigs.find((config) => {
      return config.group === creativeGroups.DEFAULT
        && config.extra.alias === this.loadedUrl.searchParams.get("result_style")
    }) || resolveCreativeConfigByGroup(creativeGroups.DEFAULT);

    if (selectedConfig) {
      const creative = new Creative()
        .configureByConfig(selectedConfig)
        .setAsSelected(true);

      if (this.loadedUrl.searchParams.get("result_url")) {
        const resultUrl = decodeURIComponent(this.loadedUrl.searchParams.get("result_url"));

        creative.setTask("preparation", {resultUrl});
        creative.setTask("template", {resultUrl});
      }

      processing.addCreative(creative);
    }

    window.processingManager.start(processing);
  };

  /** @param {Processing} processing */
  handleProcessingChange = (processing) => {
    if (window.appConfig.isDebug) {
      console.info("ProcessingPage::handleProcessingChange", JSON.parse(processing.toJSON()));
    }

    const selectedCreatives = processing.creatives.filter((c) => c.isSelected && c.group !== creativeGroups.COMMON);
    const processedCreatives = selectedCreatives.filter((c) => c.isProcessed);
    const failedCreatives = selectedCreatives.filter((c) => c.isFailed);

    if (processedCreatives.length > 0) {
      logEvent(userEvents.PROCESSING_PROCESSED, {
        elapsed_time_ms: Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT),
      });

      this.props.history.replace({
        pathname: routes.RESULT,
        search: window.location.search,
      });
    } else if (failedCreatives.length === selectedCreatives.length) {
      const failedCreative = failedCreatives[0];
      const isPhotolab = failedCreative.error.type === "photolab";
      const codeContent = window.processingManager.processing.codeContent;
      const error = isPhotolab ? failedCreative.error : new Error(i18n.t("internal_error"));

      logEvent(userEvents.PROCESSING_FAILED, {
        elapsed_time_ms: Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT),
      });

      window.processingManager.stop();

      this.props.history.replace(routes.CREATE, {
        error,
        codeContent,
        step: 2,
      });
    }
  };

  render() {
    const steps = this.loadedUrl.searchParams.get("ref") === "webtemplate"
      ? {step: 2, total: 2}
      : {step: 3, total: 3};

    return <main className="create-page">
      <div className="container">
        <Loader {...steps} />
      </div>
    </main>;
  }
}

ProcessingPage.contextType = AppContext;