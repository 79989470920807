import i18n from "../i18n";
import jsQR from "jsqr";

const DATA_NUMERIC_MAX_SIZE = 3058;
const DATA_ALPHANUM_MAX_SIZE = 1852;
const DATA_BYTES_MAX_SIZE = 1274;

export function transformData(str) {
  if (/^shc:\/\d+$/.test(str)) {
    return "SHC" + str.substring(3);
  }

  return str;
}

export function checkDataSize(str) {
  const size = Buffer.byteLength(str, "utf8");

  let maxSize = DATA_BYTES_MAX_SIZE;

  if (/^\d+$/.test(str)) {
    maxSize = DATA_NUMERIC_MAX_SIZE;
  } else if (/^[0-9A-Z $\%\*\+\-\.\/\:]+$/.test(str)) {
    maxSize = DATA_ALPHANUM_MAX_SIZE;
  }

  return size > 0 && size <= maxSize;
}

export function scanImage(data) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.src = data;

    img.onerror = () => {
      reject(new Error(i18n.t("scan_error__invalid_format")));
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const width = img.width;
      const height = img.height;

      canvas.width = width;
      canvas.height = height;

      const shadowCanvasContext = canvas.getContext("2d");
      shadowCanvasContext.drawImage(img, 0, 0);

      const result = jsQR(
        shadowCanvasContext.getImageData(0, 0, width, height).data,
        width,
        height
      );

      canvas.remove();

      if (!result) {
        const error = new Error(i18n.t("scan_error__unrecognized_qrcode"));
        error.name = "UnrecognizedQrcode";

        reject(error);

        return;
      }

      resolve(result);
    };
  });
}