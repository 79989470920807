import React from "react";
import i18n from "../../i18n";
import routes from "../../routes";
import AppContext from "../../contexts/AppContext";
import SecondStepView from "./SecondStepView";
import {isEmpty} from "../../utils/validation";
import FileChooseButton from "../../components/FileChooseButton";
import ErrorModal from "../../components/ErrorModal";
import {checkDataSize, transformData} from "../../utils/qrcode";
import * as api from "../../utils/api";
import Loader from "../../components/Loader";
import {waitTask} from "../../photolab/handlers/helpers";
import {logEvent, userEvents} from "../../utils/log";
import {when} from "../../utils/etc";
import {generatePath} from "react-router";

export default class CreatePage extends React.Component {
  state = {
    data: "",
    source: null,
    isLoading: false,
    step: 1,
  };

  loadedUrl = new URL(window.location.href);

  componentDidMount() {
    logEvent(userEvents.PAGE_CREATE);

    const codeContent = (this.props.location.state && this.props.location.state.codeContent)
      ? this.props.location.state.codeContent : null;

    const step = (this.props.location.state && this.props.location.state.step)
      ? this.props.location.state.step : null;

    const source = (this.props.location.state && this.props.location.state.source)
      ? this.props.location.state.source : null;

    const error = (this.props.location.state && this.props.location.state.error)
      ? this.props.location.state.error : null;

    this.setState({
      data: codeContent || "",
      step: (step && codeContent) ? step : 1,
      source: source
    });

    if (error) {
      this.context.pushModal(<ErrorModal
        key="CreatePage_MessageModal"
        text={error.message}
        onClick={() => this.setState({isLoading: false})} />);
    }
  }

  handleCameraButtonClick = () => {
    this.props.history.push({
      pathname: routes.QRCODE_CAMERA,
      search: window.location.search,
    });
  };

  handleGalleryButtonClick = (file) => {
    this.setState({
      isLoading: true,
    });

    const isPDF = file.type === "application/pdf";
    const createFile = isPDF ? api.createPDF : api.createFile;
    const source = isPDF ? "pdf" : "image";

    createFile(file)
      .then((file) => api.createTask("qrcode_scan", {content_url: file.url}))
      .then((taskResult) => waitTask(taskResult.id))
      .then((taskResult) => {
        const data = taskResult.result;

        if (!checkDataSize(data)) {
          logEvent(userEvents.QRCODE_SCAN_FAIL, {
            source: source,
            reason: "long_data",
          });

          this.context.pushModal(<ErrorModal
            key="CreatePage_MessageModal"
            title={i18n.t("scan_error__long_qrcode")}
            text={i18n.t("scan_error__comment")}
            onClick={() => this.setState({isLoading: false})} />);

          return;
        }

        logEvent(userEvents.QRCODE_SCAN_SUCCESS, {
          source: source,
          data: data,
        });

        if (this.loadedUrl.searchParams.get("file_url")) {
          this.handleGoToProcessing(data, source);

          return;
        }

        this.setState({
          isLoading: false,
          data,
          source: source,
          step: 2,
        });
      })
      .catch((error) => {
        logEvent(userEvents.QRCODE_SCAN_FAIL, {
          source: source,
          reason: error.message,
        });

        const text = error.message === "unreachable_content_face"
          ? i18n.t("scan_error__unreachable_content_face")
          : i18n.t("scan_error__comment");

        this.context.pushModal(<ErrorModal
          key="CreatePage_MessageModal"
          title={i18n.t("scan_error__unrecognized_qrcode")}
          text={text}
          onClick={() => this.setState({isLoading: false})} />);
      });
  }

  handleManualFormSubmit = (e) => {
    e.preventDefault();

    const data = transformData(this.state.data);

    if (isEmpty(data)) {
      this.context.pushModal(<ErrorModal
        key="CreatePage_MessageModal"
        title={i18n.t("scan_error__invalid_link")}
        text={i18n.t("scan_error__comment")}
        onClick={() => this.setState({isLoading: false})} />);

      return;
    }

    if (!checkDataSize(data)) {
      logEvent(userEvents.QRCODE_SCAN_FAIL, {
        source: "manual",
        reason: "long_data",
      });

      this.context.pushModal(<ErrorModal
        key="CreatePage_MessageModal"
        title={i18n.t("scan_error__long_qrcode")}
        text={i18n.t("scan_error__comment")}
        onClick={() => this.setState({isLoading: false})} />);

      return;
    }

    if (this.state.source === "manual") {
      logEvent(userEvents.QRCODE_SCAN_SUCCESS, {
        source: "manual",
        data: data,
      });
    }

    if (this.loadedUrl.searchParams.get("file_url")) {
      this.handleGoToProcessing(data, this.state.source);

      return;
    }

    this.setState({
      data,
      step: 2,
    });
  };

  handleTextChange = (e) => {
    this.setState({
      data: e.target.value,
      source: "manual",
    });
  };

  handleFileSelected = (file) => {
    logEvent(userEvents.PHOTO_SELECT);

    this.props.history.replace(routes.UPLOAD, {
      file: file,
      codeContent: this.state.data,
      source: this.state.source,
    });
  };

  handleGoToProcessing = (codeContent, source) => {
    window.processingManager.clear();

    this.props.history.replace({
      pathname: generatePath(routes.PROCESSING),
      state: {
        codeContent,
        source,
      },
      search: window.location.search
    });
  }

  renderInputLink = () => {
    const label = when(window.clientConfig.splitGroupId, {
      1: null,
      2: i18n.t("create__or_enter_link"),
      3: i18n.t("create__or_enter_link"),
      4: i18n.t("create__enter_link"),
      5: i18n.t("create__enter_link"),
      6: null,
      7: i18n.t("create__or_enter_link"),
      8: i18n.t("create__or_enter_link"),
      9: i18n.t("create__enter_link"),
      10: i18n.t("create__enter_link"),
    });

    const placeholder = when(window.clientConfig.splitGroupId, {
      1: i18n.t("create__text_placeholder"),
      2: "https://....",
      3: "http://instagram.com/@yourname",
      4: "https://....",
      5: "e.g. http://instagram.com/@yourname",
      6: i18n.t("create__text_placeholder"),
      7: "https://....",
      8: "http://instagram.com/@yourname",
      9: "https://....",
      10: "e.g. http://instagram.com/@yourname",
    });

    return <React.Fragment>
      {label && <p className="input-link-label">{label}</p>}
      <input
        className="input-link"
        name="url"
        type="text"
        placeholder={placeholder}
        value={this.state.data}
        onChange={this.handleTextChange} />
    </React.Fragment>;
  }

  render() {
    const steps = this.loadedUrl.searchParams.get("ref") === "webtemplate"
      ? {step: 1, total: 2}
      : {step: 1, total: 3};

    if (this.state.isLoading) {
      return <main className="create-page">
        <div className="container">
          <Loader {...steps} />
        </div>
      </main>;
    }

    if (this.state.step === 2) {
      return <SecondStepView
        onFileSelected={this.handleFileSelected} />
    }

    return <main className="create-page">
      <div className="container">
        <p className="steps" dangerouslySetInnerHTML={{__html: i18n.t("steps", steps)}} />
        <p className="page-subtitle" dangerouslySetInnerHTML={{__html: i18n.t("create__text_step_1")}} />

        <div className="btns-container">
          <button
            className="btn btn-selection"
            dangerouslySetInnerHTML={{__html: i18n.t("create__scan_button")}}
            onClick={this.handleCameraButtonClick} />

          <FileChooseButton
            onFileSelected={this.handleGalleryButtonClick}
            accept="image/*,application/pdf"
            className="btn btn-selection"
            children={i18n.t("create__gallery_button")} />

          <form onSubmit={this.handleManualFormSubmit} id="url-form">
            {this.renderInputLink()}
          </form>

          <button
            type="submit"
            form="url-form"
            disabled={isEmpty(this.state.data)}
            className="btn btn-next"
            children={i18n.t("create__next_button")} />
        </div>
      </div>
    </main>;
  }
}

CreatePage.contextType = AppContext;
