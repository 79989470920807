module.exports = {
  "strings": {
    "landing__title": "<span>QRtoon</span> - QR код с&nbsp;человеческим лицом",
    "landing__text_before": "Раз надо жить по QR кодам, мы решили сделать этот процесс чуть приятнее",
    "landing__text": "Наш QR код работает так же как обычный, но его намного веселее показывать. 🙃",
    "landing__try_in_web_button": "ВЕБ-ПРИЛОЖЕНИЕ (beta)",
    "landing__bot_link": "Запустить<br/>QRtoonBot",

    "create__text_step_1": "Отсканируйте QR-код или добавьте ссылку",
    "create__text_step_2": "Выберите фото для QR-кода",
    "create__text_placeholder": "Введите ссылку",
    "create__scan_button": "Отсканируйте<br>QR-код",
    "create__gallery_button": "Загрузите QR-код",
    "create__next_button": "Дальше",
    "create__photo_button": "Выберите фото",
    "create__enter_link": "Введите ссылку:",
    "create__or_enter_link": "Или введите ссылку:",

    "camera__gallery_button": "Галерея",

    "loader_processing": "Обработка…",

    "result__text_1": "Время проверить ваш QR-код 🙂",
    "result__text_2": "Скоро здесь появится еще больше стилей 🎨",
    "result__download": "Сохранить",
    "result__choose_photo": "Выбрать другое фото",
    "result__create_new": "Создать новый QR-код",
    "result__refresh": "Другой стиль",
    "result__more_styles": "Другой стиль",

    "steps": "Шаг {{step}}<span>/{{total}}</span>",

    "scan_error__invalid_format": "Неподдерживаемый формат изображения. Подойдет JPEG или PNG",
    "scan_error__unrecognized_qrcode": "Не удалось распознать QR-код",
    "scan_error__long_qrcode": "Упс... твой QR-код содержит слишком много данных, и его нельзя скомбинировать с картинкой. 💾",
    "scan_error__invalid_link": "Пожалуйста, проверьте корректность своей ссылки",
    "scan_error__comment": "Не получилось считать код. Попробуйте другое изображение или введите ссылку",
    "scan_error__unreachable_content_face": "Для начала нужна фотография QR-кода. Ваше селфи потребуется позже. 😉",

    "internal_error": "Возникла неизвестная ошибка...",
    "error__default_message": "Что-то пошло не так.",
    "error__network_message": "Пожалуйста, проверьте подключение к Интренету",
    "error__api_code_1": "Возникла внутренняя ошибка",
    "error__api_code_2": "Неверные параметры",
    "error__api_code_3": "Во время обработки произошла ошибка",
    "error__api_code_401": "Неавторизованный запрос",
    "error__api_code_404": "Не найдено",
    "error__api_code_410": "Запрашиваемый контент удален",
    "error__api_code_415": "Неподдерживаемый формат файла",
  },
};

